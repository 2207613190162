<template>
	<MyTooltip :trigger="trigger"
		display-mode="white">
		<div slot="title" class="drone-status-tooltip-content">
			{{$tenum('drone.status',status + '.description')}}
		</div>
		<span :class="cssClasses">
			{{$tenum('drone.status',status)}}
			<component :is="icon" v-if="!hideIcon" alt=''  title='' class="icon" />
		</span>
	</MyTooltip>
</template>

<script>
import NewIcon from "vue-material-design-icons/NewBox.vue"
import ActiveIcon from '@components/icon/DroneIcon.vue'
import DecommissionIcon from "vue-material-design-icons/AirplaneOff.vue"
import BackupIcon from "vue-material-design-icons/RecycleVariant.vue"
import {isStringEmpty as _isStringEmpty} from "@utils/stringUtil"
import MyTooltip from "@components/common/MyTooltip.vue"

export default {
	components : {
		"new-icon" : NewIcon,
		"active-icon" : ActiveIcon,
		"decommission-icon" : DecommissionIcon,
		"backup-icon" : BackupIcon,
		MyTooltip ,
	} ,
	props : {
		status : {
			type : String,
			default : ''
		} ,
		hideIcon : {
			type : Boolean,
			default : false,
		} ,
		tagStyle : {
			type : Boolean,
			default : false,
		} ,
		trigger : {
			type :String,
			default : 'click|hover'
		}
	} ,
	computed : {
		icon() {
			if (!_isStringEmpty(this.status)) {
				return this.status+"-icon";
			} else {
				return undefined
			}
		} ,
		cssClasses() {
			return [
				"drone-status" ,
				"drone-status-"+this.status,
				{
					"drone-status-tag" : this.tagStyle
				}
			]
		} ,
	} ,
}
</script>

<style lang="less" scoped>
.drone-status-mixins(@status,@color,@text-color)  {
	.drone-status-@{status} {
		color : @color;
		&.drone-status-tag {
			color : @text-color;
			background : @color;
			&:hover {
				opacity: 0.85;
			}
		}
	}
}

.drone-status {
	white-space: nowrap;
	&.drone-status-tag {
		display : inline-block;
		padding : 0 7px;
		border-radius : @border-radius-base;
		font-size : 12px;
		line-height: 20px;
	}
	.icon {
		fill : currentColor;
		width : 1em;
		height : 1em;
	}
}
.drone-status-tooltip-content {
	padding: 2px 4px;
}


.drone-status-mixins(new,@warning-color,@white);
.drone-status-mixins(active,@success-color,@white);
.drone-status-mixins(decommission,@error-color,@white);
.drone-status-mixins(backup,@text-muted,@white);
</style>
