<i18n src="@i18n/drone/drone.th.yaml" locale="th" lang="yaml"></i18n>
<template>
	<a-table
		v-bind="$attrs"
		class="mytable mytable-scroll drone-table"
		size="small"
		:pagination="pagination"
		:row-selection="rowSelection"
		v-on="$listeners">
		<a-table-column
			:title="$t('common.table.column.no')"
			align="center"
			class="column-row-no">
			<template v-slot="text,record,index">
				{{index + 1 + ((pagination.current - 1) * pagination.pageSize)}}
			</template>
		</a-table-column>
		<a-table-column
			key="name" :sorter="true"
			:title="$t('drone.field.name')"
			data-index="drone.name"
			:width="150">
			<template v-slot="text,record">
				<my-router-link :clickable="linkClickable" name="drone/view" :param-value="record.drone.serial_no" param-name="serial_no">
					{{text}}
				</my-router-link>
			</template>
		</a-table-column>
		<a-table-column
			key="serial_no" :sorter="true"
			:title="$t('drone.field.serial_no')"
			data-index="drone.serial_no"
			:width="135">
			<template v-slot="text,record">
				<my-router-link :clickable="linkClickable" name="drone/view" :param-value="record.drone.serial_no" param-name="serial_no">
					{{text}}
				</my-router-link>
			</template>
		</a-table-column>
		<a-table-column
			v-if="showPixhawk"
			key="pixhawk_serial_no" :sorter="true"
			:title="$t('drone.field.pixhawk_serial_no')"
			data-index="drone.pixhawk_serial_no"
			:width="200">
			<template v-slot="text,record">
				<my-router-link :clickable="linkClickable" name="drone/view" :param-value="record.drone.serial_no" param-name="serial_no">
					{{text}}
				</my-router-link>
			</template>
		</a-table-column>
		<a-table-column
			v-if="!hideModel" key="drone_model_name"
			:sorter="true"
			:title="$t('drone.field.drone_model_id')"
			data-index="model"
			:width="150">
			<template v-slot="droneModel">
				<my-router-link :clickable="linkClickable" name="drone_model/view" :param-value="droneModel.id" auth-action="read" auth-module="droneModel">
					{{droneModel.name}}
				</my-router-link>
			</template>
		</a-table-column>
		<a-table-column
			v-if="!hideLot" key="drone_lot_name"
			:sorter="true"
			:title="$t('drone.field.drone_lot_id')"
			data-index="lot"
			:width="150">
			<template v-slot="droneLot">
				<my-router-link :clickable="linkClickable" name="drone_lot/view" :param-value="droneLot.id">
					{{droneLot.lot_name}}
				</my-router-link>
			</template>
		</a-table-column>
		<a-table-column
			key="company_name" :sorter="true"
			:title="$t('drone.field.company_name')"
			data-index="drone.company_name"
			:width="150">
			<template v-slot="text, record">
				<my-router-link :clickable="linkClickable" name="company/view" :param-value="record.drone.company_id" auth-action="read" auth-module="company">
					{{text}}
				</my-router-link>
			</template>
		</a-table-column>

		<a-table-column
			key="warranty_start_date" :sorter="true"
			:title="$t('drone.field.warranty_start_date')"
			data-index="drone.warranty_start_date"
			align="center"
			:width="150">
			<template v-slot="text">
				<span>{{text | date('LL','-')}}</span>
			</template>
		</a-table-column>

		<a-table-column
			key="customer_name" :sorter="true"
			:title="$t('drone.field.customer')"
			data-index="owner.name"/>
		<a-table-column
			v-if="!hideFirmware"
			key="firmware" :sorter="true"
			:title="$t('drone.field.firmware')"
			:width="100"
			align="center"
			data-index="firmware.name"/>
		<a-table-column
			v-if="!hidePhone"
			key="phone" :sorter="true"
			:title="$t('drone.field.phone')"
			:width="100"
			align="center"
			data-index="owner.phone"/>
		<a-table-column
			v-if="!hideEmail"
			key="email" :sorter="true"
			:title="$t('drone.field.email')"
			align="center"
			data-index="owner.email"/>
		<a-table-column
			v-if="!hideStatus"
			key="drone_status" :sorter="true"
			:title="$t('drone.field.status')"
			data-index="drone.drone_status"
			align="center"
			:width="150">
			<template v-slot="text">
				<DroneStatus :status="text" />
			</template>
		</a-table-column>
		<a-table-column
			v-if="canRegister && !hideRegister"
			class="mytable-action-col"
			:width="50"
			align="center">
			<template v-slot="{ drone }">
				<my-router-link v-if="isShowRegister(drone)" name="drone/register" :param-value="drone.serial_no" param-name="serial_no">
					<a-icon type="file-add" />
				</my-router-link>
			</template>
		</a-table-column>
	</a-table>
</template>

<script>
import {Table} from "ant-design-vue"
import DroneStatus from "@components/drone/DroneStatus.vue"

export default {
	components : {
		"a-table" : Table, "a-table-column" : Table.Column,
		DroneStatus
	},
	props : {
		pagination : {
			type : null,
			default : () => []
		},
		hideLot : {
			type : Boolean,
			default : false
		},
		hideModel : {
			type : Boolean,
			default : false
		},
		showPixhawk : {
			type : Boolean,
			default : false,
		},
		hideRegister: {
			type: Boolean,
			default: false
		},
		hideStatus: {
			type: Boolean,
			default: false
		},
		hideFirmware: {
			type: Boolean,
			default: false
		},
		hidePhone: {
			type: Boolean,
			default: true
		},
		hideEmail: {
			type: Boolean,
			default: true
		},
		isSelectable: {
			type: Boolean,
			default: false
		},
		linkClickable: {
			type: Boolean,
			default: true
		}
	},
	data() {
    return {
      selectedRowKeys: [], // Check here to configure the default column
    };
  },
	computed : {
		canRegister() {
			return this.$authorize('register','drone')
		},
		rowSelection() {
			if(this.isSelectable) {
				return {
					type: 'radio',
					selectedRowKeys: this.selectedRowKeys,
					onChange: (selectedRowKeys, selectedRows) => {
						this.selectedRowKeys = [...selectedRowKeys]
						this.$emit('change-drone', selectedRowKeys[0]);
					},
					getCheckboxProps: record => {
						this.clearSelected()
						return {
							props:{
								name: 'droneTable',
							}
						}
					},
				};
			}
			return undefined;
		}
	},
	methods : {
		isShowRegister(drone) {
			return this.canRegister && drone.drone_status == 'new'
		},
		clearSelected(){
			this.selectedRowKeys = []
		}
	}
}
</script>

<style lang="less" scoped>
.drone-table::v-deep table {
	min-width : 1200px;
}
</style>
